<template>
  <b-card
    title="Main info"
  >
    <b-card-text>
      <validation-observer
        ref="refFormObserver"
      >
        <b-form>
          <b-table-simple
            class="mb-4"
          >
            <b-tbody>
              <b-tr>
                <b-th
                  class="p-1"
                >Name</b-th>
                <b-td
                  class="p-1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      :value="styleName"
                      :state="getValidationState(validationContext)"
                      trim
                      @input="(val) => $emit('update:style-name', val)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th
                  class="p-1"
                >Brand Ref</b-th>
                <b-td
                  class="p-1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Brand Ref"
                    rules="required"
                  >
                    <b-form-input
                      id="ref"
                      :value="styleRef"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      @input="(val) => $emit('update:style-ref', val)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-form>
      </validation-observer>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BForm, BFormInput, BFormInvalidFeedback, BTableSimple,
  BTbody, BTd, BTh, BTr,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BTr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    styleRef: {
      type: String,
      required: true,
    },
    styleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
