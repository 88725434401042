<template>
  <div>
    <b-card
      :title="title"
    >
      <b-card-text>
        <b-form-checkbox-group
          id="materials"
          :checked="materials"
        >
          <b-table
            ref="refMaterialListTable"
            class="position-relative"
            :items="fetchMaterials"
            responsive
            fixed
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Without Materials"
            @refreshed="tableRefreshed"
          >

            <!-- Column: Copy -->
            <template #cell(copy)="data">
              <div class="text-nowrap">
                <b-form-checkbox
                  :value="data.item.id"
                  @change="(val) => $emit('update:materials', val)"
                />
              </div>
            </template>

            <!-- Column: Category type -->
            <template #cell(category_type)="data">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  {{ data.item.category_type }}
                </div>
                <div>
                  <b-avatar
                    v-for="media in imageMedia(data.item.style_material_media)"
                    :key="media.id"
                    :src="media.image"
                    :style="{width: '3em', height: '3em'}"
                    :title="media.name"
                    square
                    class="mr-1"
                  />
                </div>
                <div>
                  <b-img
                    v-for="color in data.item.colors"
                    :key="color.id"
                    blank
                    :alt="color.name"
                    :blank-color="color.color"
                    :style="{width: '1.5em', height: '1.5em'}"
                    :title="color.name"
                    class="mr-1"
                    rounded="circle"
                  />
                  <template
                    v-if="data.item.other_colors"
                  >
                    <b-img
                      v-for="color in data.item.other_colors"
                      :key="color.id"
                      blank
                      :alt="color.name"
                      :blank-color="color.color"
                      :style="{width: '1.5em', height: '1.5em'}"
                      :title="color.name"
                      class="mr-1"
                      rounded="circle"
                    />
                  </template>
                  <template
                    v-if="data.item.other_colors2"
                  >
                    <b-img
                      v-for="color in data.item.other_colors2"
                      :key="color.id"
                      blank
                      :alt="color.name"
                      :blank-color="color.color"
                      :style="{width: '1.5em', height: '1.5em'}"
                      :title="color.name"
                      class="mr-1"
                      rounded="circle"
                    />
                  </template>
                </div>
              </div>
            </template>

            <!-- Column: Ref -->
            <template #cell(description)="data">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <div
                    class="align-text-top"
                    style="white-space: pre-line"
                  >
                    {{ data.item.specifics_description }}
                  </div>
                  <div class="align-text-top">
                    {{ data.item.dimensions_description }}
                  </div>
                </div>
                <feather-icon
                  v-if="data.item.placement"
                  v-b-tooltip.hover
                  icon="TargetIcon"
                  class="d-inline mr-1"
                  :title="data.item.placement"
                />
                <feather-icon
                  v-if="data.item.feed && data.item.feed.length != 0"
                  icon="MessageCircleIcon"
                  class="d-inline mr-1"
                />
              </div>
            </template>
          </b-table>
        </b-form-checkbox-group>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCardText, BFormCheckbox, BFormCheckboxGroup, BImg, BTable,
} from 'bootstrap-vue'
import useStyleMaterialsList from '../styles-edit/useStyleMaterialsList'

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BFormCheckbox,
    BFormCheckboxGroup,
    BImg,
    BTable,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    materials: {
      type: Array,
      required: true,
    },
    fetchMaterials: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      refMaterialListTable,
      showDate,
    } = useStyleMaterialsList()

    const tableColumns = [
      { key: 'category_type' },
      { key: 'description' },
      { key: 'copy' },
    ]

    const imageMedia = allMedia => allMedia.filter(media => media.image)

    const tableRefreshed = () => {
      const ids = refMaterialListTable.value.computedItems.map(item => item.id)
      emit('update:materials', ids)
    }

    return {
      showDate,
      tableRefreshed,

      tableColumns,
      refMaterialListTable,
      imageMedia,
    }
  },
}
</script>

<style>

</style>
